import './login.less';

import { Authentication } from '../../common/auth/Authentication';
import { AuthServerApiBase, AccountServiceBase } from "config";

angular.module("twlogin").controller('LoginController', ['$q', 'authHelper', function ($q, authHelper) {

    var self = this;
    var auth = new Authentication(AuthServerApiBase, $q.when);

    self.lidata = {
        username: '',
        password: '',
        busy: false
    };

    self.isInitialized = false;
    self.basicLoginAvailable = false;

    if (auth.isAuthenticated) {
        auth.fetch(AccountServiceBase + '/token/isvalid').then(rsp => {
            if (!self.isInitialized) {
                if (rsp.status === 200) {
                    self.isAuthenticated = true;
                    self.isInitialized = true;
                } else {
                    auth.logout();
                    self.isInitialized = true;
                }
            }
        });
    } else {
        self.isInitialized = true;
    }

    function doLogout() {
        auth.logout();
        self.isAuthenticated = auth.isAuthenticated;
    }

    function doInitLogin(isTroman) {
        if (self.lidata.busy) return;
        self.lidata.busy = true;
        auth.initLogin(self.lidata.username, isTroman)
            .then((data) => {
                self.lidata.busy = false;
                self.lidata.error = null;
                self.lidata.fullerror = null;
                if(data.Result === 0) {
                    self.basicLoginAvailable = true;
                }
                else {
                    window.location.href = data.Response.RedirectUrl;
                }
            })
            .catch(err => {
                self.lidata.busy = false;
                self.lidata.error = (err && err.message) || "Okänt fel";
                self.lidata.fullerror = err;
                doLogout();
            });
    }

    function doLogin(isTroman) {
        if (self.lidata.busy) return;
        self.lidata.busy = true;
        auth.login(self.lidata.username, self.lidata.password)
            .then(() => authHelper.checkScope(isTroman))
            .then(() => {
                self.lidata.busy = false;
                self.lidata.error = null;
                self.lidata.fullerror = null;
                window.location.href = isTroman ? 'troman/' : "valman/";
            })
            .catch(err => {
                self.lidata.busy = false;
                self.lidata.error = (err && err.message) || "Okänt fel";
                self.lidata.fullerror = err;
                doLogout();
            });
    }

    self.loginValman = function () {
        if(self.basicLoginAvailable) {
            doLogin(false);
        }
        else {
            doInitLogin(false);
        }
    };

    self.login = function () {
        if(self.basicLoginAvailable) {
            doLogin(true);
        }
        else {
            doInitLogin(true);
        }
    };

    self.logout = function () {
        doLogout();
    };
}]);
