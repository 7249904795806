import { Authentication } from '../../../common/auth/Authentication';
import { AuthServerApiBase, AccountServiceBase } from "config";

angular.module("twlogin").controller('SSOAutoLoginController', ['$location', '$q', function ($location, $q) {

    var self = this;
    var auth = new Authentication(AuthServerApiBase, $q.when);

    self.lidata = {
      busy: false,
      error: null
    };

    self.customerId = $location.search().customer;
    self.system = $location.search().system;

    function initLogin() {
      auth.initSSOAutoLogin(self.customerId, self.system === "troman")
        .then((data) => {
            self.lidata.busy = false;
            self.lidata.error = null;
            self.lidata.fullerror = null;
            window.location.href = data.Response.RedirectUrl;
        })
        .catch(err => {
            self.lidata.busy = false;
            self.lidata.error = (err && err.message) || "Okänt fel";
            self.lidata.fullerror = err;
            auth.logout();
          });
    }

    if(self.customerId && self.system) {
      if(self.system === "troman" || self.system === "valman") {
        if (auth.isAuthenticated) {
          auth.fetch(AccountServiceBase + '/token/isvalid').then(rsp => {
              if (!self.isInitialized) {
                  if (rsp.status === 200) {
                      self.isAuthenticated = true;
                      self.isInitialized = true;
                      window.location.href = `/${self.system}/`;
                    } else {
                      auth.logout();
                      self.isInitialized = true;
                      initLogin();
                  }
              }
          });
        } else {
          initLogin();
        }
      }
      else {
        self.lidata.error = `Kan inte påbörja inloggningssession. Felaktigt system (${self.system}).`;
      }
    }
    else if(!self.customerId) {
      self.lidata.error = "Kan inte påbörja inloggningssession. Kund-id (customer) saknas.";
    }
    else if(!self.system) {
      self.lidata.error = "Kan inte påbörja inloggningssession. System (system) saknas.";
    }

}]);
