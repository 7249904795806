import { AnonServer } from '../../common/account/AnonServer';

angular.module("twlogin").controller('ForgotController', ['$q', 'errorDialog', function ($q, errorDialog) {
    let busy = false;

    this.submitted = false;
    this.username = '';

    this.canSubmit = function () {
        return this.form.$valid && !busy;
    };

    this.submit = function () {
        if (!this.canSubmit()) return;

        busy = true;

        var srv = new AnonServer($q.when);

        srv.forgotPassword(this.username)
            .then(() => {
                this.submitted = true;
            }).catch(err => {
                errorDialog.show(err);
            }).finally(() => {
                busy = false;
            });
    };
}]);
