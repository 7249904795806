import { AnonServer } from '../../common/account/AnonServer';
import { Authentication } from '../../common/auth/Authentication';

angular.module("twlogin").controller('RegisterController', ['$q', '$stateParams', 'errorDialog', function ($q, $stateParams, errorDialog) {
    var data = JSON.parse(atob($stateParams.token));
    var username = data.u;
    var token = data.t;
    let busy = false;

    this.username = username;

    this.canSubmit = function () {
        return this.form.$valid && !busy;
    };

    this.submit = function () {
        if (!this.canSubmit()) return;

        busy = true;

        var srv = new AnonServer($q.when);

        srv.register(username, token, this.newPassword)
            .then(() => {
                Authentication.clearLogin();
                location.href = "/";
            }, err => {
                errorDialog.show(err);
            })
            .finally(() => busy = false);
    };
}]);
