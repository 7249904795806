angular.module('twlogin', ['ui.router', 'twshared']);

angular.module('twlogin').config(['$logProvider', function ($logProvider) {
  $logProvider.debugEnabled(false);
}]);

angular.module('twlogin').config(['$locationProvider', function ($locationProvider) {
  $locationProvider.html5Mode(true);
}]);

angular.module('twlogin').config(['$stateProvider', '$urlServiceProvider', function ($stateProvider, $urlServiceProvider) {

  $stateProvider.state("login", {
      url: "",
      controller: "LoginController as ctrl",
      template: require('./login/login.html'),
  });

  $stateProvider.state("register", {
      url: "/account/register?{token}",
      controller: "RegisterController as ctrl",
      template: require('./reset/register.html'),
  });

  $stateProvider.state("reset", {
      url: "/account/reset?{token}",
      controller: "RegisterController as ctrl",
      template: require('./reset/reset.html'),
  });

  $stateProvider.state("forgot", {
      url: "/account/forgot",
      controller: "ForgotController as ctrl",
      template: require('./forgot/forgot.html'),
  });

  $stateProvider.state("ssoautologin", {
    url: "/sso/login",
    controller: "SSOAutoLoginController as ctrl",
    template: require('./sso/autologin/sso-auto-login.html'),
  });

  $stateProvider.state("ssocallback", {
    url: "/sso/callback",
    controller: "SSOCallbackController as ctrl",
    template: require('./sso/callback/sso-callback.html'),
  });

  $urlServiceProvider.rules.otherwise({state:'login'});
}]);

