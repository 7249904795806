import { Authentication } from '../../../common/auth/Authentication';
import { AuthServerApiBase } from "config";

/**
 * @typedef { import('../SSOModels').LoginBySSOSession } LoginBySSOSession
 */

angular.module("twlogin").controller('SSOCallbackController', ['$location', '$q', 'authHelper', function ($location, $q, authHelper) {

    var self = this;
    var auth = new Authentication(AuthServerApiBase, $q.when);

    self.lidata = {
        busy: false,
        error: null
    };

    /**
     * 
     * @param {string} encodedRelayState 
     * @returns {LoginBySSOSession}
     */
    function getLoginBySSOSessionPayload(encodedRelayState) {
        if(encodedRelayState) {
            const relayStateString = atob(encodedRelayState);
            const loginBySSOSession = JSON.parse(relayStateString);
            loginBySSOSession.sessionId = $location.search().ts_session_id;
            return loginBySSOSession;
        }
        else {
            throw new Error("Relay state was not set.");
        }
    }

    try {
        const loginBySSOSession = getLoginBySSOSessionPayload($location.search().relayState);
        self.isTroman = loginBySSOSession.CustomerScope === 0;
        self.lidata.busy = true;

        auth.loginBySSOSession(loginBySSOSession)
            .then(() => authHelper.checkScope(self.isTroman))
            .then(() => {
                self.lidata.busy = false;
                self.lidata.error = null;
                self.lidata.fullerror = null;
                window.location.href = self.isTroman ? 'troman/' : "valman/";
            })
            .catch(err => {
                self.lidata.busy = false;
                self.lidata.error = (err && err.message) || "Ett okänt fel uppstod";
                self.lidata.fullerror = err;
            });
    } catch (error) {
        self.lidata.error = "Inloggningen misslyckades. Ett ogiltigt värde returnerades.";
    }

}]);
