import '../common/css/common.less';

require('../common/twshared');

require('./loginapp');
require('./login/LoginController');
require('./reset/RegisterController');
require('./forgot/ForgotController');
require('./sso/autologin/SSOAutoLoginController');
require('./sso/callback/SSOCallbackController');
